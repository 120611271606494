import CognitoUserHelper from '@/services/CognitoUserHelper'

export const authState = state => state.authState
export const loading = state => state.loading
export const authFlowUser = state => state.authFlowUser

export const username = state => state.username
export const email = state => state.email

export const user = state => {
  const helper = new CognitoUserHelper(state)
  return helper.getCognitoUser()
}

export const isAuthenticated = state => state.authState === 'signed-in'
