import AuthService from '@/services/AuthService'

export const setAuthState = ({ commit, state }, authState) => {
  if (!state.states.includes(authState)) {
    // console.error('invalid state', authState);
  }
  commit('SET_AUTH_STATE', authState)
}

export const resetAuthState = ({ commit }) => commit('SET_AUTH_STATE', undefined)

export const setAuthFlowUser = ({ commit }, user) => commit('SET_AUTH_FLOW_USER', user)

export const refreshUser = async ({ commit }) => {
  commit('SET_LOADING', true)

  try {
    const authData = await AuthService.refreshUser()

    if (authData) {
      commit('SET_AUTH_DATA', authData)
      commit('SET_AUTH_STATE', 'signed-in')
    }
  } catch (error) {
    // console.error('Error during token refresh or user retrieval:', error)
    commit('SET_AUTH_STATE', 'signed-out')
    commit('CLEAR_AUTH_DATA')
  } finally {
    commit('SET_LOADING', false)
  }
}

export const clearAuthData = ({ commit }) => {
  commit('CLEAR_AUTH_DATA')
}

export const handleAuthEvent = ({ dispatch }, payload) => {
  switch (payload.event) {
    case 'signIn':
    case 'tokenRefresh':
      dispatch('refreshUser')
      break
    case 'signOut':
      dispatch('clearAuthData')
      break
    case 'tokenRefresh_failure':
      // console.error('Token refresh failed. Logging out.')
      dispatch('clearAuthData')
      break
    default:
      // console.log(`Unhandled auth event: ${payload.event}`)
  }
}
