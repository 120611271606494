import axios from 'axios'
import ElasticSearch from './ElasticSearch'

const resource = 'townships'

export default class TownshipsES extends ElasticSearch {
  static get indexes () {
    return this.getEnvironmentIndexForResource(resource)
  }

  static async getTownships () {
    await this.ensureIndex(resource)
    const data = {
      from: 0,
      size: 400
    }

    return axios.post(`/${this.endpoint}/${this.indexes}/_search`, data)
  }
}
