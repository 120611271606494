import Vue from 'vue'
import VueRouter from 'vue-router'
import Instrumenten from '../views/Instrumenten.vue'
import Werklandschap from '../views/Werklandschap.vue'
import Klantkenmerk from '../views/Klantkenmerk.vue'
import Nieuws from '../views/Nieuws.vue'
import Handreiking from '@/components/handreiking/Handreiking'

Vue.use(VueRouter)

const routes = [
  {
    path: '/env/:slug',
    redirect: '/'
  },
  {
    path: '/shared',
    component: () => import(/* webpackChunkName: "empty_layout" */ '../layouts/EmptyLayout.vue'),
    children: [
      {
        path: 'instrument/:jwt',
        name: 'share_instrument',
        component: () => import(/* webpackChunkName: "instrument_detail" */ '../components/instrumenten/shared/InstrumentByJwt.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "environment_layout" */ '../layouts/EnvironmentLayout.vue'),
    children: [
      {
        path: '',
        name: 'environment_detail',
        component: () => import(/* webpackChunkName: "environment_detail" */ '../views/Environment/EnvironmentDetail.vue')
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
      },
      {
        path: 'nieuws',
        component: Nieuws,
        children: [
          {
            path: ':id',
            name: 'news_article',
            component: () => import(/* webpackChunkName: "nieuws_artikel" */ '../components/nieuws/Artikel.vue')
          }
        ]
      },
      {
        path: 'instrumenten',
        component: Instrumenten,
        children: [
          {
            path: '',
            name: 'environment_instrumenten',
            component: () => import(/* webpackChunkName: "instruments_search" */ '../components/instrumenten/InstrumentsSearch.vue')
          },
          {
            path: 'beoordeling/:slug/:uuid([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})?',
            name: 'environment_instrument_rating',
            component: () => import(/* webpackChunkName: "instrument_detail" */ '../components/instrumenten/InstrumentRatings.vue')
          },
          {
            path: ':uuid([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})',
            name: 'environment_instrument_uuid',
            component: () => import(/* webpackChunkName: "instrument_detail" */ '../components/instrumenten/InstrumentByUuid.vue')
          },
          {
            path: ':slug/:uuid([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})?',
            name: 'environment_instrument_select',
            component: () => import(/* webpackChunkName: "instrument_select" */ '../components/instrumenten/InstrumentSelector.vue')
          }
        ]
      },
      {
        path: 'werklandschap',
        component: Werklandschap,
        children: [
          {
            path: '',
            name: 'environment_werklandschap',
            component: () => import(/* webpackChunkName: "werklandschap_lijst" */ '../components/werklandschap/WerklandschapLijst.vue')
          },
          {
            path: ':tile_code',
            name: 'environment_werklandschap_detail',
            component: () => import(/* webpackChunkName: "werklandschap_detail" */ '../components/werklandschap/WerklandschapDetail.vue')
          }
        ]
      },
      {
        path: 'klantkenmerk',
        component: Klantkenmerk,
        children: [
          {
            path: '',
            name: 'environment_klantkenmerk',
            component: () => import(/* webpackChunkName: "werklandschap_lijst" */ '../components/klantkenmerk/KlantkenmerkLijst.vue')
          }
        ]
      },
      {
        path: 'handreiking',
        component: Handreiking,
        children: [
          {
            path: '',
            name: 'environment_handreiking',
            component: () => import(/* webpackChunkName: "handleiding" */ '../components/handreiking/Handreiking.vue')
          }
        ]
      },
      {
        path: 'contact',
        name: 'environment_contact',
        component: () => import(/* webpackChunkName: "definities" */ '../views/InstrumentVerzoek.vue')
      },
      {
        path: 'begrippen',
        name: 'environment_definities',
        component: () => import(/* webpackChunkName: "definities" */ '../views/Definities.vue')
      },
      {
        path: 'dienstverlening',
        name: 'environment_dienstverlening',
        component: () => import(/* webpackChunkName: "dienstverlening" */ '../views/Dienstverlening.vue')
      },
      {
        path: 'links',
        name: 'environment_links',
        component: () => import(/* webpackChunkName: "links" */ '../views/Links.vue')
      },
      {
        path: 'faq',
        name: 'environment_faqs',
        component: () => import(/* webpackChunkName: "faqs" */ '../views/Faq/FaqOverview.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
