export const updateVersion = ({ commit }) => {
  commit('SET_VERSION', process.env.VUE_APP_VERSION)
  commit('SET_UPDATED_AT', new Date())
  commit('SET_EXPIRATION_DATE')
}

export const checkVersion = ({ getters, dispatch }) => {
  if (getters.isBehind() || getters.isExpired()) {
    Promise.all([
      dispatch('elastic/clientCharacteristics/initClientCharacteristics', true, { root: true }),
      // we dont want to save all environment data anymore
      // dispatch('elastic/environments/initEnvironments', true, { root: true }),
      dispatch('elastic/environments/clearEnvironments', true, { root: true }),
      // dispatch('elastic/newsItems/initNewsItems', true, { root: true }),
      // we never use providers from storage. Maybe remove module
      // dispatch('elastic/providers/initProviders', true, { root: true }),
      dispatch('elastic/tiles/initTiles', true, { root: true }),
      dispatch('elastic/townships/initTownships', true, { root: true }),
      dispatch('environment/initEnvironment', true, { root: true })
    ]).finally(() => dispatch('updateVersion'))
  }
}
