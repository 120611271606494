export const initialized = state => state.initialized
export const isExpired = (state) => () => {
  const expirationDate =
    state.expirationDate instanceof Date
      ? state.expirationDate
      : new Date(state.expirationDate)

  return expirationDate < new Date()
}
export const environment = state => state.environment
export const featuredOrganisations = state => state.environment ? state.environment.GetoondeOrganisaties : null
export const featuredAreas = state => state.environment ? state.environment.GetoondeGebieden : null
