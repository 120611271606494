export const initialized = state => state.initialized
export const loading = state => state.loading
export const isExpired = (state) => () => {
  const expirationDate =
    state.expirationDate instanceof Date
      ? state.expirationDate
      : new Date(state.expirationDate)

  return expirationDate < new Date()
}
export const townships = state => state.townships
export const townshipByName = state => name => state.townships.find(township => township.NaamGemeente === name)
export const townshipByCode = state => code => state.townships.find(township => township.CdGemeente === code)
