import { Auth } from 'aws-amplify'
import store from '@/store'

class AuthService {
  static initiatePeriodicLoginCheck () {
    setInterval(async () => {
      // console.log('checking login')
      try {
        await this.ensureValidToken()
      } catch (error) {
        // console.error('Periodic token check failed:', error)
        await store.dispatch('auth/clearAuthData')
      }
    }, 5 * 60 * 1000) // Elke 5 minuten
  }

  static async ensureValidToken () {
    const session = await Auth.currentSession()
    const isTokenValid = session.getAccessToken().getExpiration() > Date.now() / 1000

    if (!isTokenValid) {
      // console.warn('Access token expired, refreshing session.')
      await this.getCurrentAuthenticatedUser()
    }
  }

  static async getCurrentAuthenticatedUser () {
    const session = await Auth.currentSession()

    const isTokenValid = session.getAccessToken().getExpiration() > Date.now() / 1000
    if (!isTokenValid) {
      // console.warn('Access token has expired.')
      throw new Error('Access token expired')
    }

    const cognitoUser = await Auth.currentAuthenticatedUser()

    const idToken = session.getIdToken().getJwtToken()
    const accessToken = session.getAccessToken().getJwtToken()
    const refreshToken = session.getRefreshToken().getToken()
    const emailAttr = cognitoUser.attributes?.email || null

    // Return user data
    return {
      username: cognitoUser.getUsername(),
      idToken,
      accessToken,
      refreshToken,
      email: emailAttr
    }
  }

  static async refreshUser () {
    return await this.getCurrentAuthenticatedUser()
  }
}

export default AuthService
