import axios from 'axios'
import ElasticSearch from './ElasticSearch'

const resource = 'environments'

export default class EnvironmentsES extends ElasticSearch {
  static get indexes () {
    return this.getEnvironmentIndexForResource(resource)
  }

  static async getEnvironments () {
    await this.ensureIndex(resource)

    const searchParams = {
      size: 400
    }
    return axios.post(`/${this.endpoint}/${this.indexes}/_search`, searchParams)
  }
}
