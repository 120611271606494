export const SET_AUTH_STATE = (state, payload) => { state.authState = payload }
export const SET_LOADING = (state, payload) => { state.loading = payload }
export const SET_AUTH_FLOW_USER = (state, cognitoUser) => { state.authFlowUser = cognitoUser }

export const SET_AUTH_DATA = (state, authData) => {
  state.username = authData.username
  state.idToken = authData.idToken
  state.accessToken = authData.accessToken
  state.refreshToken = authData.refreshToken
  state.email = authData.email
}

export const CLEAR_AUTH_DATA = (state) => {
  state.username = null
  state.idToken = null
  state.accessToken = null
  state.refreshToken = null
  state.email = null
}
