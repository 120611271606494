export const initialized = state => state.initialized
export const loading = state => state.loading
export const isExpired = (state) => () => {
  const expirationDate =
    state.expirationDate instanceof Date
      ? state.expirationDate
      : new Date(state.expirationDate)

  return expirationDate < new Date()
}
export const clientCharacteristics = state => state.clientCharacteristics
export const clientCharacteristicByName = state => name => state.clientCharacteristics.find(clientCharacteristic => clientCharacteristic.NaamKlantkenmerk === name)
