import { Auth } from 'aws-amplify'
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken
} from 'amazon-cognito-identity-js'

class CognitoUserHelper {
  constructor (state) {
    this.state = state
    this.amplifyConfig = Auth.configure()
  }

  isValidState () {
    const { username, idToken, accessToken, refreshToken } = this.state
    return username && idToken && accessToken && refreshToken
  }

  getUserPool () {
    return new CognitoUserPool({
      UserPoolId: this.amplifyConfig.userPoolId,
      ClientId: this.amplifyConfig.userPoolWebClientId
    })
  }

  getCognitoUser () {
    if (!this.isValidState()) {
      return null
    }

    const userPool = this.getUserPool()
    const cognitoUser = new CognitoUser({
      Username: this.state.username,
      Pool: userPool
    })

    const idToken = new CognitoIdToken({ IdToken: this.state.idToken })
    const accessToken = new CognitoAccessToken({ AccessToken: this.state.accessToken })
    const refreshToken = new CognitoRefreshToken({ RefreshToken: this.state.refreshToken })

    const sessionData = {
      IdToken: idToken,
      AccessToken: accessToken,
      RefreshToken: refreshToken
    }

    const signInUserSession = new CognitoUserSession(sessionData)
    cognitoUser.setSignInUserSession(signInUserSession)

    return cognitoUser
  }
}

export default CognitoUserHelper
